import React from 'react'
import { IArticleProps } from '.'
import './readMoreStyle.less'
import './style.less'

export function ArticleCF4222({ headerClass, textClass, linkClass }: IArticleProps) {
    return (
        <div className="guide-container">
            <h2 className={headerClass}>
                <a
                    target="_blank"
                    rel="noreferrer"
                    className={linkClass}
                    href="https://skat.dk/skat.aspx?oid=1977318"
                >
                    C.F.4.2.2.2 - Ophold uden for riget
                </a>
            </h2>
            <p className={`${textClass}`}>
                Dette afsnit beskriver, hvad der anses for og ikke anses for ophold uden for riget i
                relation til{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    className={linkClass}
                    href="https://www.retsinformation.dk/eli/lta/2019/806#P33A"
                >
                    LL § 33 A
                </a>
                .
            </p>
            <p className={`${textClass}`}>
                Afsnittet indeholder:
                <br />
                - Geografisk afgrænsning
                <br />
                - Søfolk
                <br />
                - Flypersonel
                <br />
                - Arbejde udført i Danmark
                <br />
                - Sygdom, barsel og korterevarende kursus
                <br />- Oversigt over domme, kendelser, afgørelser, SKM-meddelelser mv.
            </p>
        </div>
    )
}
