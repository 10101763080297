import React from 'react'
import Container from '../components/Landing/Container'
import { ArticleLovTekst } from '../components/Articles/LovTekst'
import { ArticleCF4222 } from '../components/Articles/cf4222'
import { ArticleCF4223 } from '../components/Articles/cf4223'
import { ArticleCF4224 } from '../components/Articles/cf4224'
import { ArticleHistorie } from '../components/Articles/Historie'
import SEO from '../components/seo'

export default function LovenPage({ setCookieValue }) {
    return (
        <Container>
            <SEO title="Loven" />
            <div className="background-div-outer-inv" style={{ paddingBottom: '2rem' }}>
                <div className="content-row" style={{ paddingTop: '2rem' }}>
                    <h1 className="inverse-bh">Ligningsloven § 33 A</h1>
                </div>
                <div className="content-row" style={{ marginTop: '2rem' }}>
                    <div className="text-row">
                        <p
                            className="inverse-h"
                            style={{ textAlign: 'center', marginBottom: '70px' }}
                        >
                            Her finder du lovteksten til LL § 33A samt links til Skattestyrelsens
                            lovfortolkninger og retsafgørelser i sager om LL § 33A.
                        </p>
                    </div>
                </div>
                <div className="content-row">
                    <ArticleLovTekst
                        headerClass="inverse-bh"
                        linkClass="text-link"
                        textClass="inverse-h"
                    />
                </div>
                <div className="content-row">
                    <ArticleCF4222
                        headerClass="inverse-bh"
                        linkClass="text-link"
                        textClass="inverse-h"
                    />
                </div>
                <div className="content-row">
                    <ArticleCF4223
                        headerClass="inverse-bh"
                        linkClass="text-link"
                        textClass="inverse-h"
                    />
                </div>
                <div className="content-row">
                    <ArticleCF4224
                        headerClass="inverse-bh"
                        linkClass="text-link"
                        textClass="inverse-h"
                    />
                </div>
                <div className="content-row">
                    <ArticleHistorie
                        headerClass="inverse-bh"
                        linkClass="text-link"
                        textClass="inverse-h"
                    />
                </div>
            </div>
        </Container>
    )
}
